<template>
  <div class="hold-transition login-page bg-login view-user-login">
    <div class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <div class="row">
            <div class="col-12 text-center mb-3">
              <img
                class="logo-login-img"
                src="@/assets/img/dentcloud-logo-darkblue.png"
              />
              <h3 class="logo-caption mt-2">
                <span class="">D</span>ent<span class="">C</span>loud
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="alert alert-warning alert-dismissible fade show"
                role="alert"
                v-if="statusLogin"
              >
                กรุณาตรวจสอบ อีเมลล์ หรือ รหัสผ่าน ให้ถูกต้อง
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="setStatusLogin"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <b-form @submit.prevent="submit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="email">อีเมลล์</label>
                  <div class="input-group">
                    <input
                      id="login-input-login-view"
                      class="form-control form-control-sm"
                      v-model.trim="loginForm.username"
                      required
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>รหัสผ่าน</label>
                  <div class="input-group">
                    <input
                      id="password-input-login-view"
                      type="password"
                      class="form-control form-control-sm"
                      v-model="loginForm.password"
                      required
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-row class="mb-2">
              <b-col cols="12">
                <b-form-checkbox
                  size="sm"
                  v-model="loginForm.rememberMe"
                  @change="saveToLocalStorage"
                  >จดจำฉัน</b-form-checkbox
                >
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block btn-sm">
                  เข้าสู่ระบบ
                </button>
              </div>
            </div>
          </b-form>
          <div class="row">
            <div class="col-12">
              <span class="mb-0 mt-3 ml-auto">
                <a href="/user/forgotpassword" class="text-center"
                  >ลืมรหัสผ่าน?</a
                >
              </span>
              <!-- <p class="mb-0 mt-3">
                มีบัญชีหรือยัง?
                <a href="/register" class="text-center">สร้างบัญชีใหม่</a>
                หรือ
                <a href="/user/forgotpassword" class="text-center"
                  >ลืมรหัสผ่าน?</a
                >
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="small-text text-muted">
      {{ appVersion.appVersionNo }}
    </div>
    <!-- <div class="small-text text-muted float-right">
      {{
        "Version: " +
        appVersion.appVersionNo +
        " Date: " +
        appVersion.appVersionDate
      }}
    </div> -->
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "Login",
  components: {
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
      },
      statusLogin: false,
    };
  },
  created() {
    this.fetchAppVersion();
    this.loadFromLocalStorage();
  },
  computed: {
    ...mapGetters({
      appVersion: "moduleMaster/getAppVersion",
      getLoginApiStatus: "moduleAuth/getLoginApiStatus",
    }),
    // ...mapGetters("auth", {
    //   getLoginApiStatus: "getLoginApiStatus",
    // }),
  },
  methods: {
    ...mapActions({
      login: "moduleUser/login",
      fetchAppVersion: "moduleMaster/fetchAppVersion",
      actionLoginApi: "moduleAuth/loginApi",
    }),

    // ...mapActions("auth", {
    //   actionLoginApi: "loginApi",
    // }),

    oldLogin(loginForm) {
      return new Promise((resolve, reject) => {
        this.login(loginForm)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => reject(err));
      });
    },

    async submit() {
      this.isLoading = true;
      try {
        const res = await this.actionLoginApi(this.loginForm);
        if (res.status == 200) {
          if (this.$route.query.from) {
            await this.$router.replace({
              path: this.$route.query.from,
            });
          } else {
            await this.$router.replace({ path: "/clinic" });
          }
        }
        return;
      } catch (err) {
        let statusCode = err.response ? err.response.data.errorCode : null;
        if (statusCode == 401) {
          this.loginForm.password = "";
          this.statusLogin = true;
        } else if (statusCode >= 400) {
          let errMessage = err.statusText
            ? err.statusText
            : "ระบบขัดข้อง ขออภัยในความไม่สะดวก";
          this.showDialogToast("danger", errMessage);
        } else {
          this.showDialogToast("danger", err.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    setStatusLogin() {
      this.statusLogin = false;
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    saveToLocalStorage(e) {
      localStorage.setItem("rememberMe", e);
    },
    loadFromLocalStorage() {
      this.loginForm.rememberMe = Boolean(
        JSON.parse(localStorage.getItem("rememberMe"))
      );
    },
  },
};
</script>

<style>
.logo-caption {
  font-family: "Noto Sans Thai";
  font-size: 32px;
  font-weight: 900;
  color: #1d5d8e;
}
.logo-login-img {
  width: 50%;
}
</style>
